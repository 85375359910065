<template>
    <categories-component/>
</template>

<script>
    import CategoriesComponent from "@/components/projects/CategoriesComponent";

    export default {
        name: "Categories",
        title: "Categorías | Private",
        components: {CategoriesComponent}
    }
</script>

<style scoped>

</style>