<template>
    <v-container fluid class="container--fluid fill-height text-left pa-0 backcolor" style="width: 100%">
        <v-row>
            <v-img src="@/assets/images/header-bg.jpg"
                   class="align-end"
                   transition="slide-x-transition"
                   width="100%" height="300">
                <v-row align="end" justify="center">
                    <v-col cols="12">
                        <div class="fill-height container--fluid px-5 px-md-16 pb-10">
                            <span class="font-weight-thin text-h5 mx-2 text-md-h2 white--text">
                               Categorías
                            </span>
                            <v-divider dark class="mt-2"/>
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </v-row>
        <v-row>
            <v-container fluid class="container--fluid fill-height text-left pa-auto pa-md-16 pt-0 pt-md-0 backcolor">
<!--                <v-row align="center" justify="center">
                    <v-col cols="12">
                        <div class="fill-height container&#45;&#45;fluid">
                            <v-banner tile single-line class="mt-10">
                                <template v-slot:actions>
                                    <v-btn
                                        color="primary"
                                        x-small
                                        rounded
                                        class="text-capitalize"
                                        text
                                        @click="getCategories">
                                        Ver todos
                                    </v-btn>
                                    <v-btn v-for="(item, index) in itemsFilter" :key="index"
                                           color="primary"
                                           x-small
                                           rounded
                                           class="text-capitalize"
                                           text
                                           @click="showFilterProjects(item.status)">
                                        {{ item.text }}
                                    </v-btn>
                                </template>
                            </v-banner>
                        </div>
                    </v-col>
                </v-row>-->
                <v-row align="start" justify="start" class="text-left mt-10">
                    <v-col v-for="item in categories"
                           :key="item.id"
                           cols="12" md="3"
                           class="mx-0 px-auto">
                        <v-card
                            class="border-info"
                            rounded="lg"
                            elevation="3"
                        >
                            <div class="text-center">
                                <v-img
                                    :lazy-src="require('@/assets/images/no-image.jpg')"
                                    :src="item.image_url != null ? item.image_url : require('@/assets/images/no-image.jpg')"
                                    class="rounded-t-lg"
                                    height="300"
                                    max-width="100%"
                                    position="top">
                                    <template v-slot:placeholder>
                                        <v-row
                                            align="center"
                                            class="fill-height ma-0"
                                            justify="center">
                                            <v-progress-circular
                                                color="grey lighten-5"
                                                indeterminate>
                                            </v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </div>
                            <v-card-title class="mt-3 text-subtitle-1 font-weight-light">
                                {{ item.name }}
                            </v-card-title>
                            <v-card-text>
                                <!-- long paragraph -->
                                <div class="caption black--text">
                                    {{ item.description }}
                                </div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn link text color="blue" small @click="showCategory(item.id)">
                                    Conoce más >
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center" class="ma-10" v-if="categories.length === 0">
                    <div class="text-subtitle-1 text-md-h4">
                        No hay Categorías en el catálogos...
                    </div>
                </v-row>
            </v-container>
        </v-row>
    </v-container>
</template>

<script>
    import categoriesService from "@/providers/CategoriesService";

    export default {
        name: "CategoriesComponent",
        data: () => ({
            categories: [],
            model: null,
        }),
        methods: {
            getCategories() {
                // Lista de Proyectos
                categoriesService.getViewList().then(record => {
                    // console.log('Record: ', record.value);
                    this.categories = record.value;
                });
            },
            showCategory(category_id) {
                this.$router.push({
                    name: "CategoryProfile",
                    params: {
                        id: category_id
                    }
                });
            }
        },
        mounted() {
            this.categories = [];
            this.getCategories();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
    .backcolor {
        background-color: #ffffff;
        margin-top: 0px;
    }
    /*.v-btn {
        text-transform: none !important;
    }*/
    .my-slider > .v-slide-group__next.theme--light.v-icon {
        color: rgb(234, 10, 10) !important;
        font-size: 24pt !important;
    }
    .div-hover {
        cursor: pointer;
    }
    .card {
        min-height: 150px;
        border-width: 0px;
        /*padding: 5px;*/
        transition: 0.5s ease-out;
    }
    .card .v-image {
        /*margin-bottom: 5px;*/
        transition: 0.75s;
    }
    .card h1 {
        margin-bottom: 10px;
    }
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        align-content: end;
        justify-content: center;
        opacity: .7;
        position: absolute;
        width: 100%;
    }
    .zoom-efect {
        transform: scale(1.1);
    }
    .up {
        transform: translateY(-20px);
        transition: 0.5s ease-out;
    }
    .zoom-img {
        overflow: hidden;
        border-radius: 10px;
    }
    .zoom-img > img {
        object-fit: cover;
        transition: all .3s ease-in-out;
    }
    .zoom-img:hover > img {
        transform: scale(1.05);
    }
    .zoom-image {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        /* VERZÖGERUNG */
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        overflow: hidden;
    }
    .zoom-image:hover {
        -webkit-transform: scale(1.07);
        -moz-transform: scale(1.07);
        -ms-transform: scale(1.07);
        -o-transform: scale(1.07);
        transform: scale(1.07);
    }
</style>